<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.culture')" />
    <div class="top-img">
      <img
        src="http://image.wearetogether.com.cn/culture-ukraine.jpg"
        width="100%"
      />
    </div>

    <div class="part part1">
      <h1>{{ setting.part1.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part1.des }}
      </div>
      <div class="cont" style="margin-top: 24px">
        <div class="cont-left">
          <img
            src="http://cdn.topartsintermational.com/culture-img1.png"
            width="100%"
          />
        </div>
        <div class="cont-right">
          <p>{{ setting.part1.cont }}</p>
        </div>
      </div>
    </div>

    <div class="part part2">
      <h1>{{ setting.part2.title }}</h1>
      <div style="font-size: 18px; color: #f2f2f2">
        {{ setting.part2.des }}
      </div>
      <div class="row">
        <div class="left">
          <br>
          <p>{{ setting.part2.cont }}</p>
        </div>
        <div class="right">
          <img
            src="http://cdn.topartsintermational.com/culture-img2-s.jpg"
            width="100%"
          />
          <!-- <img
            src="http://cdn.topartsintermational.com/culture-img2.jpg"
            width="100%"
            @click="visible = true"
          /> -->
        </div>
      </div>
      <div class="video">
        <video
          src="http://cdn.topartsintermational.com/legao.mp4"
          controls
          width="100%"
        ></video>
      </div>
      <!-- <div class="carousel culture">
        <el-carousel
          indicator-position="outside"
          :autoplay="false"
          height="615px"
        >
          <el-carousel-item v-for="item in setting.carousel" :key="item.id">
            <div class="picWall">
              <div
                class="picItem"
                v-for="node in item.imgs"
                :key="node.id"
                @click="handleVisible(node)"
              >
                <img :src="node.url" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
    </div>
    <Footer />
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://image.wearetogether.com.cn/culture-fir.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <el-dialog
      :visible.sync="visible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video :src="link" controls width="1200px" style="display: block"></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from "../../components/footer"
export default {
  name: "Culture",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      dialogVisible: true,
      visible: false,
      link: '',
      setting: {
        part1: {
          title: this.$t('message.culture.part1.title'),
          des: this.$t('message.culture.part1.des'),
          cont: this.$t('message.culture.part1.cont'),
        },
        part2: {
          title: this.$t('message.culture.part2.title'),
          des: this.$t('message.culture.part2.des'),
          cont: this.$t('message.culture.part2.cont'),
        },
        carousel: [
          {
            id: 1,
            imgs: [
              {
                id: 1,
                url: 'http://cdn.topartsintermational.com/culture-1.jpg',
                link: 'http://cdn.topartsintermational.com/culture-1.mp4',
              },
              {
                id: 2,
                url: 'http://cdn.topartsintermational.com/culture-2.jpg',
                link: 'http://cdn.topartsintermational.com/culture-2.mp4',
              },
              {
                id: 3,
                url: 'http://cdn.topartsintermational.com/culture-3.jpg',
                link: 'http://cdn.topartsintermational.com/culture-3.mp4',
              },
              {
                id: 4,
                url: 'http://cdn.topartsintermational.com/culture-4.jpg',
                link: 'http://cdn.topartsintermational.com/culture-4.mp4',
              },
              {
                id: 5,
                url: 'http://cdn.topartsintermational.com/culture-5.jpg',
                link: 'http://cdn.topartsintermational.com/culture-5.mp4',
              },
              {
                id: 6,
                url: 'http://cdn.topartsintermational.com/culture-6.jpg',
                link: 'http://cdn.topartsintermational.com/culture-6.mp4',
              },
            ],
          },
          {
            id: 2,
            imgs: [
              {
                id: 7,
                url: 'http://cdn.topartsintermational.com/culture-7.jpg',
                link: 'http://cdn.topartsintermational.com/culture-7.mp4',
              },
              {
                id: 8,
                url: 'http://cdn.topartsintermational.com/culture-8.jpg',
                link: 'http://cdn.topartsintermational.com/culture-8.mp4',
              },
              {
                id: 9,
                url: 'http://cdn.topartsintermational.com/culture-9.jpg',
                link: 'http://cdn.topartsintermational.com/culture-9.mp4',
              },
              {
                id: 10,
                url: 'http://cdn.topartsintermational.com/culture-10.jpg',
                link: 'http://cdn.topartsintermational.com/culture-10.mp4',
              },
              {
                id: 11,
                url: 'http://cdn.topartsintermational.com/culture-11.jpg',
                link: 'http://cdn.topartsintermational.com/culture-11.mp4',
              },
              {
                id: 12,
                url: 'http://cdn.topartsintermational.com/culture-12.jpg',
                link: 'http://cdn.topartsintermational.com/culture-12.mp4',
              },
            ],
          },
          {
            id: 3,
            imgs: [
              {
                id: 13,
                url: 'http://cdn.topartsintermational.com/culture-13.jpg',
                link: 'http://cdn.topartsintermational.com/culture-13.mp4',
              },
              {
                id: 14,
                url: 'http://cdn.topartsintermational.com/culture-14.jpg',
                link: 'http://cdn.topartsintermational.com/culture-14.mp4',
              },
              {
                id: 15,
                url: 'http://cdn.topartsintermational.com/culture-15.jpg',
                link: 'http://cdn.topartsintermational.com/culture-15.mp4',
              },
              {
                id: 16,
                url: 'http://cdn.topartsintermational.com/culture-16.jpg',
                link: 'http://cdn.topartsintermational.com/culture-16.mp4',
              },
              {
                id: 17,
                url: 'http://cdn.topartsintermational.com/culture-17.jpg',
                link: 'http://cdn.topartsintermational.com/culture-17.mp4',
              },
              {
                id: 18,
                url: 'http://cdn.topartsintermational.com/culture-18.jpg',
                link: 'http://cdn.topartsintermational.com/culture-18.mp4',
              },
            ],
          },
        ],
      },
    }
  },
  methods: {
    handleVisible (item) {
      this.visible = true
      this.link = item.link
    }
  },
};
</script>

<style scoped lang="less">
p {
  margin: 0;
  padding: 0;
}
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
  background: url("http://cdn.topartsintermational.com/culture-bg.jpg")
    no-repeat;
  background-size: cover;
}
.top-img {
  width: 100%;
  overflow: hidden;
  img {
    transition: all 0.6s;
    &:hover {
      transform: scale(1.2);
      transition: all 0.6s;
    }
  }
}
.part {
  width: 1200px;
  text-align: center;
  color: #fff;
  padding: 16px 0;
  margin: 50px auto 0;
  line-height: 24px;
}
.part h1 {
  margin: 0;
  padding: 0;
  line-height: 36px;
}
.part2 {
  .video {
    width: 1200px;
    margin: 16px auto 0;
  }
}
.cont {
  width: 100%;
  display: flex;
}
.cont-left {
  width: 600px;
  overflow: hidden;
}
.cont-right {
  width: 600px;
  background: url("http://cdn.topartsintermational.com/warriors-img2.jpg")
    no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-indent: 2em;
  padding: 80px 80px 0;
  text-align: justify;
  box-sizing: border-box;
}
.cont img {
  transition: all 0.6s;
}
.cont img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
.row {
  justify-content: space-between;
  margin-top: 24px;
  .col {
    width: 48%;
  }
  .left {
    width: 665px;
    background: url("http://cdn.topartsintermational.com/ancient-img3.jpg")
      no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-indent: 2em;
    padding: 45px 50px 0;
    text-align: justify;
    box-sizing: border-box;
  }
  .right {
    width: 515px;
    overflow: hidden;
    img {
      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.2);
      transition: all 0.6s;
    }
  }
}
.carousel {
  margin-top: 25px;
}
.picWall {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  padding: 8px;
  box-sizing: border-box;
  .picItem {
    overflow: hidden;
    width: 32%;
    &:nth-child(n + 4) {
      margin-top: 8px;
    }
    img {
      display: block;
      transition: all 0.6s;
      width: 100%;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style>